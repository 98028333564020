<template>
  <div class="profile__wrapper">
    <div class="categorisation categorisation__wrapper" v-if="structure">
      <div
        class="profile__section"
        :style="{ order: group.order_position }"
        v-for="(group, id_0) in structure"
        :key="`${group.uuid}${id_0}`"
      >
        <h4 class="title-h4 profile__title" v-if="!!group.title">
          {{ group.title }}
        </h4>
        <div
          class="categorisation__block"
          :style="{ order: subgroup.order_position }"
          v-for="(subgroup, id_1) in group.subgroups"
          :key="`${subgroup.uuid}${id_1}`"
        >
          <h5 class="title-h5 profile__subtitle" v-if="!!subgroup.title">
            {{ subgroup.title }}:
          </h5>
          <div
            :class="[
              subgroup.criterias[0].type === 'images'
                ? 'image__grid'
                : 'profile__grid',
            ]"
          >
            <div
              v-for="(item, id_2) in subgroup.criterias"
              :key="`${item.uuid}${id_2}`"
              :class="`${item.classnames}`"
              :style="{ order: item.order_position }"
              :id="item.uuid"
            >
              <text-field
                v-if="['text', 'number', 'rating'].includes(item.type)"
                v-model="item['value']"
                :is-error="item.error == true"
                :stars-read-only="editIsDisabled(item.uuid)"
                :title="item.title"
                :is-rate="item.type === 'rating'"
                :is-icon="item.type === 'text_icon'"
                :value-mask="item.mask"
                :is-inactive="editIsDisabled(item.uuid)"
                icon-name="actual"
                :can-comment="canComment"
                is-commentable
                :uuid="item.uuid"
                comment-to="hotel"
              />
              <FileUpload
                v-else-if="['file', 'files'].includes(item.type)"
                :id="item.uuid"
                v-model="item['value']"
                :title="item.title"
                :is-multiple="item.type == 'files'"
                :is-download-only="editIsDisabled(item.uuid)"
                is-commentable
                :uuid="item.uuid"
                :can-comment="canComment"
                comment-to="hotel"
              />
              <PhotoUpload
                v-else-if="['images'].includes(item.type)"
                :id="item.uuid"
                :uuid="item.uuid"
                v-model="item['value']"
                :photos-uploaded="item['value']"
                :title="item.title"
                is-multiple
                is-commentable
                :is-disabled="editIsDisabled(item.uuid)"
                :can-comment="canComment"
                comment-to="hotel"
              />
              <div
                :class="`${item.classnames}`"
                v-else-if="item.type === 'switcher'"
              >
                <Switcher
                  :uuid="item.uuid"
                  :can-comment="canComment"
                  :is-commentable="commentsVisible"
                  :is-inactive="true"
                  v-model="item.value"
                  comment-to="hotel"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-else>
      <LoaderContent />
    </template>
    <div class="no-print">
      <ButtonsBlock
        :buttons="buttonsArray"
        :disabled="!isExistgCategorizationСomments"
        :disabledDownLoad="!isCertificate"
        :disabledAchive="!isAchive"
        @print="print()"
        @sendForImprovement="sendForImprovement()"
        @sendToComission="sendToComission()"
        @leaveUnpended="leaveUnpended()"
        @downloadCertificate="downloadCertificate()"
        @downloadPDF="downloadPDF()"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getCategorizationFormStructure } from '@/api/services/hotel.js';
import FileUpload from '@/elements/FileUpload.vue';
import PhotoUpload from '@/elements/PhotoUpload.vue';
import ButtonsBlock from '@/elements/ButtonsBlock.vue';
import Switcher from '@/elements/Switcher.vue';
import LoaderContent from '@/elements/LoaderContent.vue';

export default {
  components: {
    FileUpload,
    PhotoUpload,
    ButtonsBlock,
    Switcher,
    LoaderContent,
  },

  data() {
    return {
      dataLoaded: false,
      buttonsArray: [],
      commentsVisible: false,
      canComment: false,
      canEdit: '',
      voteSchedule: '',
      structure: null,
    };
  },

  async created() {
    let categorizationRequestUUUID = this.$route.params.id;

    await this.getCategorizationStructure();
    await this.$store.dispatch(
      'getCategorizationDart',
      categorizationRequestUUUID
    );

    this.statusModelHandler();
    if (this.commentsVisible) {
      await this.$store.dispatch('getCommentsDart', categorizationRequestUUUID);
    }

    this.populateFormWithData();
  },

  computed: {
    ...mapGetters([
      'getCategorizationDart',
      'getCommentsDart',
      'getStatusModel',
      'isExistgCategorizationСomments',
    ]),

    prefilledCriteris() {
      let prefilledCriteris = {};
      this.getCategorizationDart.criteria.forEach((criteria) => {
        prefilledCriteris[criteria.categorization_criterion_uuid] = criteria;
      });
      return prefilledCriteris;
    },

    isCertificate() {
      return !!this.getCategorizationDart?.certificate;
    },

    isAchive() {
      return !!this.getCategorizationDart?.archive;
    },
  },

  methods: {
    async getCategorizationStructure() {
      let data = { lang: 'uk', uuid: this.$route.params.id };
      try {
        let res;
        res = await getCategorizationFormStructure(data);
        if (res.status === 200) {
          this.structure = res.data.data.structure;
        }
      } catch (e) {
        console.log(
          '>>> error getCategorizationFormStructure',
          e.response.data.error
        );
      }
    },

    print() {
      window.print();
    },

    statusModelHandler() {
      this.getStatusModel.forEach((element) => {
        if (
          element.filing_status == this.getCategorizationDart.filing_status &&
          element.review_status == this.getCategorizationDart.review_status
        ) {
          this.commentsVisible = element.comments_visible;
          this.buttonsArray = element.buttons;
          this.canComment = element.can_comment;
          this.canEdit = element.edit;
          this.voteSchedule = element.schedule;
        }
      });
    },

    editIsDisabled(uuid) {
      let editIsDisabled = false;
      switch (this.canEdit) {
        case 'can_edit_all_fields':
          editIsDisabled = false;
          break;
        case 'not_allowed':
          editIsDisabled = true;
          break;
        case 'can_edit_commented_fields':
          this.getCommentsHotel[uuid]
            ? (editIsDisabled = false)
            : (editIsDisabled = true);
          break;
      }
      return editIsDisabled;
    },

    populateFormWithData() {
      this.structure.map((el) =>
        el.subgroups.map((s) =>
          s.criterias.map(async (s) => {
            if (s.uuid in this.prefilledCriteris) {
              if (Array.isArray(this.prefilledCriteris[s.uuid].value)) {
                let valueToPopulate = [];

                for (let file in this.prefilledCriteris[s.uuid].value) {
                  let convertedFile = await this.createFile(
                    this.prefilledCriteris[s.uuid].value[file].original_url,
                    this.prefilledCriteris[s.uuid].value[file].mime
                  );
                  valueToPopulate.push(convertedFile);
                }
                s.value = valueToPopulate;
              } else {
                s.value = this.prefilledCriteris[s.uuid].value;
              }
            }
          })
        )
      );
    },

    async createFile(url, type) {
      const response = await fetch(url);
      const data = await response.blob();
      const metadata = {
        type: type,
      };
      return new File([data], url.split('/').at(-1), metadata);
    },

    groupGrid(group) {
      if (group.criterias[0].type == 'images') {
        return 'image__grid';
      }
      return 'profile__grid';
    },

    async sendForImprovement() {
      await this.$store.dispatch(
        'getCommentsDart',
        this.getCategorizationDart.uuid
      );
      await this.$store.dispatch(
        'sendForImprovement',
        this.getCategorizationDart.uuid
      );
    },

    sendToComission() {
      this.$store.dispatch('sendToComission', this.getCategorizationDart.uuid);
    },

    leaveUnpended() {
      this.$router.push({ name: 'dart_hotel-categorizations' });
    },

    downloadCertificate() {
      this.$store.dispatch(
        'downloadFile',
        this.getCategorizationDart.certificate.attachment.original_url
      );
    },

    downloadPDF() {
      this.$store.dispatch(
        'downloadFile',
        this.getCategorizationDart.archive.original_url
      );
    },
  },
};
</script>

<style lang="sass" scoped>
.profile__subtitle
  margin-bottom: 4rem
</style>
